import styled from "styled-components";

const S = {
    main: styled.main`
        height: 100%;
        overflow-y: scroll;
        margin: 60px 0 60px !important;
        padding-bottom: 60px;
    `,
};

export default S;
