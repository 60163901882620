import styled, { css } from "styled-components";

const S = {
    container: styled.div<{ $reverse: boolean }>`
        display: flex;
        ${({ $reverse }) => (!$reverse ? null : css`
            flex-direction: row-reverse;
        `)};
        align-items: flex-end;
        justify-content: space-between;
        gap: 48px;

        @media (max-width: 1200px) {
            align-items: center;
            flex-direction: column-reverse;
            gap: 16px;
        }
    `,
    image: styled.img`
        width: 550px;
        max-width: calc(100vw - 48px);
    `,
    content: styled.div`
        display: flex;
        flex-direction: column;
        gap: 24px;
    `,
    label: styled.div`
        color: ${({ theme }) => theme.colors.primary.highest};
    `,
};

export default S;
