import styled, { css } from "styled-components";

const S = {

    container: styled.div<{ $isOpen: boolean }>`
        position: fixed;
        z-index: 99;
        inset: 0;
        top: ${({ theme }) => theme.headerHeight};
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.background.low};
        backdrop-filter: blur(10px);
        transition: all .5s;

        ${({ $isOpen}) => ($isOpen ? css`
            height: calc(100vh - ${({ theme }) => theme.headerHeight});
        ` : css`
            height: 0;
        `)}

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 48px;
    `,
};

export default S;
