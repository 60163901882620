import {useFormik} from "formik";
import {observer} from "mobx-react-lite";
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import requestStore from "../../../../store/requestStore";
import apiService from "../../../../services/apiServices";
import Checkbox from "../../../../styles/checkbox/Checkbox";
import Modal, {ModalProps} from "../../../../styles/modal/Modal";
import CurrencySelect, {OptionType} from "../../../../styles/textInput/Dropdown";
import TextInput from "../../../../styles/textInput/TextInput";
import S from "./exchangeFromModal.styled";
import I from "../../../../styles/textInput/input.styled";
import * as Yup from "yup";
import {CryptoCurrencyCode, CurrencyCode, ExchangeTypes} from "../../../../types/exchange";
import exchangeStore from "../../../../store/exchangeStore";
import BigNumber from "bignumber.js";
import {log} from "node:util";

const currencies: OptionType[] = [
    {label: 'USD', value: 'United States Dollar'},
    {label: 'EUR', value: 'Euro'},
    {label: 'RUB', value: 'Russian Ruble'},
    {label: 'KGS', value: 'Kyrgyz som'}
];

const cryptoCurrencies: OptionType[] = [
    {label: 'BTC', value: 'Bitcoin'},
    {label: 'ETH', value: 'Ethereum'},
    {label: 'BNB', value: 'Binance Coin'},
    {label: 'SOL', value: 'Solana'},
    {label: 'XRP', value: 'XRP'},
];

const cryptoPrecision = {
    BTC: 8,
    ETH: 18,
    BNB: 6,
    SOL: 9,
    XRP: 18,
    USDT: 18,
    USDC: 6,
    ADA: 18,
    AVAX: 18,
    DOGE: 8,
}

const USDAndEURMaxSum = 100_000.01
const USDAndEURMinSum = 49.99

const RUBAndKGSMaxSum = 10_000_000.01
const RUBAndKGSMinSum = 4_999.99
const fiatPrecision = 2
const updateRateFrequency = 60_000

const getTodayDateString = () => {
    const today = new Date()
    const hours = today.getHours() > 9 ? today.getHours() : `0${today.getHours()}`
    const minutes = today.getMinutes() > 9 ? today.getMinutes() : `0${today.getMinutes()}`
    return `${today.toLocaleDateString()} ${hours}:${minutes}`
}

const ExchangeFormModal = ({
                               isOpen,
                               title,
                               buttonText,
                               handleClose,
                               handleSubmit
                           }: ModalProps) => {
    const {t} = useTranslation();
    const [checked, setChecked] = useState(false);
    const [rate, setRate] = useState<BigNumber | string>()
    const [commonError, setCommonError] = useState('')
    const [cryptoInputPrecision, setCryptoInputPrecision] = useState(cryptoPrecision.BTC)
    const [lastNotFocusedField, setLastNotFocusedField] = useState<"receiveValue" | "sendValue">("receiveValue")
    const [lastRateChangeDate, setLastRateChangeDate] = useState("")

    const handleChange = () => setChecked(!checked);

    const collectMessage = (args: ExchangeTypes) => {
        return `${t("currencyExchange.email")}: ${args.email}\n\r`
            + `${t("currencyExchange.fullName")}: ${args.fullName}\n\r`
            + `${t("currencyExchange.send")}: ${args.sendValue} ${args.sendCurrency}\n\r`
            + `${t("currencyExchange.receive")}:  ${args.receiveValue} ${args.receiveCurrency}\n\r`
    }

    const formik = useFormik<ExchangeTypes>({
        initialValues: exchangeStore.data,
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: Yup.object().shape({
            sendValue: Yup.number().typeError(t("validation.number"))
                .when('sendCurrency', {
                    is: (value: string) => value === "USD" || value === "EUR",
                    then: (schema) => schema
                        .lessThan(USDAndEURMaxSum, t("validation.maxSendValueUSD"))
                        .moreThan(USDAndEURMinSum, t("validation.minSendValueUSD"))
                })
                .when('sendCurrency', {
                    is: (value: string) => value === "RUB" || value === "KGS",
                    then: (schema) => schema
                        .lessThan(RUBAndKGSMaxSum, t("validation.maxSendValueRUB"))
                        .moreThan(RUBAndKGSMinSum, t("validation.minSendValueRUB"))
                }),
            sendCurrency: Yup.string().required(t("validation.required")),
            receiveValue: Yup.number().typeError(t("validation.number")),
            receiveCurrency: Yup.string().required(t("validation.required")),
            fullName: Yup.string().required(t("validation.required")),
            email: Yup.string().email(t("validation.email")).required(t("validation.required")),
        }, [['sendValue', "receiveValue"]]),
        onSubmit: (values) => {
            if (Object.keys(formik.errors).length === 0) {
                const {email, fullName} = formik.values;
                requestStore.setLoadingState = true;
                apiService.sendFeedback({
                    replyTo: `${fullName} <${email}>`,
                    subject: t("currencyExchange.title"),
                    message: collectMessage(formik.values)
                }).then(() => {
                    requestStore.setLoadingState = false;
                    requestStore.setRequestState = true;
                    exchangeStore.clearData();
                    formik.resetForm();
                    handleSubmit?.();
                    formik.resetForm();
                })
                    .catch(() => {
                        requestStore.setLoadingState = false;
                        requestStore.setRequestState = false;
                        exchangeStore.setData(formik.values)
                        handleSubmit?.();
                    })
            }
        },
        onReset: () => {
            formik.setErrors({});
            setChecked(false)
            setCommonError('')
        },
    });

    const handleCloseModal = () => {
        formik.resetForm()
        exchangeStore.clearData()
        handleClose?.();
    }

    useEffect(() => {
        if (isOpen) {
            updateRate()
        }
    }, [isOpen]);


    const updateRateAndInputs = async () => {
        const newRate = await updateRate();
        if (lastNotFocusedField === "sendValue") {
            updateInputAmount(formik.values.receiveValue, true, newRate)
        } else {
            updateInputAmount(formik.values.sendValue, false, newRate)
        }
    }

    let updateRateInterval: number;

    useEffect(() => {
        window.clearInterval(updateRateInterval);
        updateRateInterval = window.setInterval(() => {
            if (isOpen) {
                updateRateAndInputs()
            }
        }, updateRateFrequency)
        return () => window.clearInterval(updateRateInterval)
    }, [isOpen, formik.values.sendCurrency, formik.values.receiveCurrency, lastNotFocusedField, formik.values.receiveValue, formik.values.sendValue]);

    useEffect(() => {
        const requiredError = t("validation.requiredValue")
        const touched = formik.touched.receiveValue || formik.touched.sendValue
        if (touched && (!formik.values.receiveValue || !formik.values.sendValue)) {
            formik.setTouched({receiveValue: true, sendValue: true}, true)
            setCommonError(requiredError)
        } else {
            setCommonError('')
        }
    }, [formik.touched.receiveValue, formik.touched.sendValue, formik.values.receiveValue, formik.values.sendValue]);

    const updateRate = async (customCurrency?: CurrencyCode, customCrypto?: CryptoCurrencyCode) => {
        if (formik.values.sendCurrency && formik.values.receiveCurrency || customCurrency && customCrypto) {
            try {
                const params = {
                    Fiat: customCurrency || formik.values.sendCurrency,
                    Crypto: customCrypto || formik.values.receiveCurrency
                }
                const newRate = await apiService.getRate(params)
                const rate = newRate?.data?.Price
                const bigRate = new BigNumber(rate);
                if (bigRate) {
                    if (bigRate !== rate) {
                        setLastRateChangeDate(getTodayDateString())
                    }
                    setRate(bigRate)
                }
                return bigRate
            } catch (e: unknown) {
                console.error('Update rate error:', e)
            }
        }
    }

    const roundCryptoOrFiat = (sum: BigNumber, isCrypto: boolean, currentCurrency?: CryptoCurrencyCode) => {
        const precision = isCrypto ? cryptoPrecision[currentCurrency as CryptoCurrencyCode] : fiatPrecision
        return sum.decimalPlaces(precision)
    }

    function exchangeCurrency(
        amount: BigNumber,
        reverted?: boolean,
        customRate?: typeof rate,
    ): BigNumber {
        if (rate === undefined) {
            return BigNumber(0);
        }
        const currentRate = customRate || rate
        const exchanged = !reverted ? amount.div(currentRate) : amount.multipliedBy(currentRate)
        const rounded = roundCryptoOrFiat(exchanged, !reverted || true, formik.values.receiveCurrency as CryptoCurrencyCode)
        return rounded;
    }

    const updateAmount = (
        amount: BigNumber,
        reverted?: boolean,
        customRate?: typeof rate,
    ): BigNumber | undefined => {
        const exchangedValue = exchangeCurrency(amount, reverted, customRate)
        let newExchangedValue;
        if (!exchangedValue.isNaN()) {
            newExchangedValue = exchangedValue;
        } else {
            newExchangedValue = 0;
        }
        if (exchangedValue) {
            if (!reverted) {
                if (exchangedValue !== BigNumber(formik.values.receiveValue || 1)) {
                    formik.setFieldValue("receiveValue", newExchangedValue);
                }
            } else {
                if (exchangedValue !== BigNumber(formik.values.sendValue || 1)) {
                    formik.setFieldValue("sendValue", newExchangedValue.toFixed(fiatPrecision));
                }
            }
        }
        return exchangedValue
    }

    const updateInputAmount = async (inputValue?: number | string, reverted?: boolean, newRate?: typeof rate) => {
        let currentRate = newRate;
        if (!newRate) {
            currentRate = rate
        }
        if (inputValue && currentRate) {
            return updateAmount(BigNumber(inputValue), reverted, currentRate)
        }
    }

    const onSendCurrencyChange = async (currency: unknown) => {
        const newRate = await updateRate(currency as CurrencyCode)
        await updateInputAmount(formik.values.sendValue, false, newRate)
        formik.validateForm()
    }

    const onReceiveCurrencyChange = async (currency: unknown) => {
        const newRate = await updateRate(undefined, currency as CryptoCurrencyCode)
        setCryptoInputPrecision(cryptoPrecision[currency as CryptoCurrencyCode])
        updateInputAmount(formik.values.receiveValue, true, newRate)
    }

    useEffect(() => {
        async function update() {
            setCryptoInputPrecision(cryptoPrecision[formik.values.receiveCurrency as CryptoCurrencyCode])
            await updateInputAmount(formik.values.receiveValue, true)
        }

        if (isOpen) {
            update()
        }
    }, [formik.values.receiveCurrency, isOpen]);

    const onReceiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateAmount(BigNumber(event.target.value), true)
    }

    const onSendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateAmount(BigNumber(event.target.value))
    }

    const dividedCourse = useMemo(() => {
        const course = BigNumber(1).div(rate || BigNumber(1))
        const roundedCourse = roundCryptoOrFiat(course, true, formik.values.receiveCurrency)
        return roundedCourse.toFixed();
    }, [rate])

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            isForm
            buttonText={buttonText}
            handleSubmit={formik.handleSubmit}
            buttonDisabled={!checked || Object.keys(formik.errors).length !== 0}
            handleClose={handleCloseModal}
        >
            <S.formContainer>
                <S.row>
                    <S.inputAndTitleContainer>
                        <S.boldText>{t("currencyExchange.send")}</S.boldText>
                        <TextInput
                            formik={formik}
                            name="sendValue"
                            placeholder="0"
                            onChange={onSendChange}
                            maxPrecision={2}
                            serverErrors={{sendValue: commonError}}
                            onFocus={() => setLastNotFocusedField("receiveValue")}
                        />
                    </S.inputAndTitleContainer>
                    <S.inputAndTitleContainer>
                        <CurrencySelect
                            formik={formik}
                            name="sendCurrency"
                            options={currencies}
                            saveFormat="label"
                            placeholder={currencies[0]?.label}
                            onChange={onSendCurrencyChange}
                            allowClear={false}
                        />
                    </S.inputAndTitleContainer>
                    {(formik.errors.sendValue && formik.touched.sendValue &&
                        <I.errorMessage>{formik.errors.sendValue}</I.errorMessage>)}
                </S.row>
                <div>
                    <S.field>
                        <S.row>
                            <S.inputAndTitleContainer>
                                <S.boldText>{t("currencyExchange.receive")}</S.boldText>
                                <TextInput
                                    formik={formik}
                                    name="receiveValue"
                                    placeholder="0"
                                    onChange={onReceiveChange}
                                    serverErrors={{receiveValue: commonError}}
                                    maxPrecision={cryptoInputPrecision}
                                    onFocus={() => setLastNotFocusedField("sendValue")}
                                />
                            </S.inputAndTitleContainer>
                            <S.inputAndTitleContainer>
                                <CurrencySelect
                                    formik={formik}
                                    options={cryptoCurrencies}
                                    name="receiveCurrency"
                                    saveFormat="label"
                                    placeholder={cryptoCurrencies[0]?.label}
                                    allowClear={false}
                                    onChange={onReceiveCurrencyChange}
                                />
                            </S.inputAndTitleContainer>
                            {(formik.errors.receiveValue && formik.touched.receiveValue &&
                                <I.errorMessage>{formik.errors.receiveValue}</I.errorMessage>)}
                        </S.row>
                        {commonError && <I.errorMessage>{commonError}</I.errorMessage>}
                    </S.field>
                    {rate &&
                        <div>
                            <I.additionalInfo>{"1" + " " + formik.values.sendCurrency + " = " + dividedCourse + " " + formik.values.receiveCurrency}</I.additionalInfo>
                            <I.additionalInfoDate>{t("currencyExchange.lastUpdate")} {lastRateChangeDate}</I.additionalInfoDate>
                        </div>
                    }
                </div>
                <S.inputAndTitleContainer>
                    <S.boldText>{t("currencyExchange.fullName")}</S.boldText>
                    <TextInput
                        formik={formik}
                        name="fullName"
                        showFiledError
                        placeholder={t("currencyExchange.formName")}
                    />
                </S.inputAndTitleContainer>
                <S.inputAndTitleContainer>
                    <S.boldText>{t("currencyExchange.email")}</S.boldText>
                    <TextInput
                        formik={formik}
                        name="email"
                        showFiledError
                        placeholder={t("currencyExchange.formEmail")}
                    />
                </S.inputAndTitleContainer>
                <S.checkboxRow>
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                    />
                    <span>
                      <S.text>
                          {t("currencyExchange.agreeTo")}
                          <S.link
                              href="terms-of-use"
                          >{t("currencyExchange.termsOfUse")}</S.link>
                      </S.text>
                 </span>
                </S.checkboxRow>
            </S.formContainer>

        </Modal>
    );
}

export default observer(ExchangeFormModal);