import React from "react";
import { useTranslation } from "react-i18next";
import S from "./MainPageAdvantages.styled";
import { ReactComponent as TechnologyIcon } from "./icons/technology.svg";
import { ReactComponent as SecureIcon } from "./icons/secure.svg";
import { ReactComponent as QualityIcon } from "./icons/quality.svg";

const icons = [TechnologyIcon, SecureIcon, QualityIcon];

interface TTexts {
    header: string,
    description: string,
}

const MainPageAdvantages: React.FC = () => {
    const { t } = useTranslation();

    const listItems = t("mainPage.advantages.items", { returnObjects: true }) as TTexts[];

    return (
        <S.container id="block-advantages">
            <S.header $primary>
                {t("mainPage.advantages.header")}
            </S.header>
            <S.content>
                {icons.map((Icon, i) => (
                    <S.item key={listItems[i].header}>
                        <S.itemHeader>
                            <S.iconContainer>
                                <Icon />
                            </S.iconContainer>
                            {listItems[i].header}
                        </S.itemHeader>
                        <S.itemDescription>
                            {listItems[i].description}
                        </S.itemDescription>
                    </S.item>
                ))}
            </S.content>
        </S.container>
    );
};

export default MainPageAdvantages;
