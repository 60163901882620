import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import S from "./documents.styled";

const TermsOfUse = () => {
    const { t } = useTranslation();
    
    return (
        <S.container>
            <S.content>
                {parse(t("publicOffer.header"))}
                {parse(t("publicOffer.warning"))}
                <section>
                    {parse(t("publicOffer.definitionsTitle"))}
                    {parse(t("publicOffer.definitions"))}
                </section>
                <section>
                    {parse(t("publicOffer.informationTitle"))}
                    {parse(t("publicOffer.information"))}
                </section>
                <section>
                    {parse(t("publicOffer.ourServicesTitle"))}
                    {parse(t("publicOffer.services"))}
                </section>
                <section>
                    {parse(t("publicOffer.acceptingProcedureTitle"))}
                    {parse(t("publicOffer.acceptingProcedureList"))}
                </section>
                <section>
                    {parse(t("publicOffer.useOfTheSiteTitle"))}
                    {parse(t("publicOffer.useOfTheSite"))}
                    {parse(t("publicOffer.consentTitle"))}
                    {parse(t("publicOffer.consentList"))}
                </section>
                <section>
                    {parse(t("publicOffer.siteInformationTitle"))}
                    {parse(t("publicOffer.siteInformation"))}
                </section>
                <section>
                    {parse(t("publicOffer.linksOfSiteTitle"))}
                    {parse(t("publicOffer.linksOfSite"))}
                </section>
                <section>
                    {parse(t("publicOffer.privacyPolicyTitle"))}
                    {parse(t("publicOffer.privacyPolicy"))}
                </section>
                <section>
                    {parse(t("publicOffer.dataProtectionTitle"))}
                    {parse(t("publicOffer.dataProtection"))}
                </section>
                <section>
                    {parse(t("publicOffer.intellectualPropertyRightsTitle"))}
                    {parse(t("publicOffer.intellectualPropertyRights"))}
                </section>
                <section>
                    {parse(t("publicOffer.trademarksTitle"))}
                    {parse(t("publicOffer.trademarks"))}
                </section>
                <section>
                    {parse(t("publicOffer.disclaimerTitle"))}
                    {parse(t("publicOffer.disclaimer"))}
                </section>
                <section>
                    {parse(t("publicOffer.thirdPartyServicesTitle"))}
                    {parse(t("publicOffer.thirdPartyServices"))}
                </section>
                <section>
                    {parse(t("publicOffer.suspensionOfServicesTitle"))}
                    {parse(t("publicOffer.suspensionOfServices"))}
                </section>
                <section>
                    {parse(t("publicOffer.indemnificationTitle"))}
                    {parse(t("publicOffer.indemnification"))}
                </section>
                <section>
                    {parse(t("publicOffer.limitationOfLiabilityTitle"))}
                    {parse(t("publicOffer.limitationOfLiability"))}
                </section>
                <section>
                    {parse(t("publicOffer.communicationLanguageTitle"))}
                    {parse(t("publicOffer.communicationLanguage"))}
                </section>
                <section>
                    {parse(t("publicOffer.amendmentTitle"))}
                    {parse(t("publicOffer.amendment"))}
                </section>
                <section>
                    {parse(t("publicOffer.governingTitle"))}
                    {parse(t("publicOffer.governing"))}
                </section>
                <section>
                    {parse(t("publicOffer.miscellaneousProvisionsTitle"))}
                    {parse(t("publicOffer.miscellaneousProvisions"))}
                </section>
                <section>
                    {parse(t("publicOffer.contactSalesTitle"))}
                    {parse(t("publicOffer.contactSales"))}
                </section>
            </S.content>
        </S.container>
    )
}

export default TermsOfUse;
