import React from "react";
import { Routes, Route } from 'react-router-dom';
import MainPage from "./pages/main/MainPage";
import Header from "./header/Header";
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import GlobalStyles from "../styles/globalStyles";
import S from "./App.styled";
import Footer from "./footer/Footer";
import PrivacyPolicy from "./pages/documents/PrivacyPolicy";
import TermsOfUse from "./pages/documents/TermsOfUse";

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
                <Header />
                <S.main id="main">
                    <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path="/terms-of-use" element={<TermsOfUse />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="*" element={<MainPage />} />
                    </Routes>
                    <Footer />
                </S.main>
        </ThemeProvider>
    );
};

export default App;
