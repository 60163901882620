import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import requestStore from "../../store/requestStore";
import NotificationsModal from "../pages/main/exchangeForm/NotificationsModal";
import ExchangeFormModal from "../pages/main/exchangeForm/ExchangeFormModal";
import HeaderLanguage from "./language/HeaderLanguage";
import { ReactComponent as MenuIcon } from "./icons/menu.svg";
import HeaderLink, { TLinkName } from "./link/HeaderLink";
import S from "./Header.styled";
import HeaderMenu from "./menu/HeaderMenu";
import {useLocation, useNavigate} from "react-router-dom";

const linkNames: TLinkName[] = ["platform", "tools", "advantages", "questions"];
const basePath = '/'

const Header: React.FC = () => {
    const [isOpenedMenu, setIsOpenedMenu] = useState(false);

    const toggleMenu = () => setIsOpenedMenu((prevValue) => !prevValue);
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);

    const {pathname} = useLocation();
    const navigate = useNavigate();


    const onLogoClick = () => {
        if(pathname === basePath){
            const main = document.getElementById(`main`);
            main?.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
        else {
            navigate(basePath)
        }
    }

    return (
        <>
            <S.header>
                <S.content>
                    <S.menuButton onClick={toggleMenu}>
                        <MenuIcon />
                    </S.menuButton>
                    <S.logo onClick={onLogoClick} />
                    <S.linksList>
                        {linkNames.map((name) => (
                            <HeaderLink key={name} name={name} />
                        ))}
                    </S.linksList>
                    <HeaderLanguage />
                    <S.button onClick={() => setModalOpen(true)}>
                        {t(`currencyExchange.exchange`)}
                    </S.button>
                </S.content>
            </S.header>
            <HeaderMenu
                open={isOpenedMenu}
                onClick={toggleMenu}
            />
            <ExchangeFormModal
                isOpen={modalOpen}
                title={t("currencyExchange.buy")}
                buttonText={t("currencyExchange.enter")}
                handleClose={() => setModalOpen(false)}
                handleSubmit={() => {
                    setModalOpen(false);
                    setNotificationModalOpen(true);
                }}
            />
            {requestStore.getIsRequestSuccess ?
                <NotificationsModal
                    isOpen={notificationModalOpen}
                    title={t("currencyExchange.successTitle")}
                    buttonText={t("currencyExchange.ok")}
                    modalBodyText={t("currencyExchange.successMessage")}
                    handleClose={() => setNotificationModalOpen(false)}
                    handleSubmit={() => setNotificationModalOpen(false)}
                    isForm={false}
                />
                :
                <NotificationsModal
                    isOpen={notificationModalOpen}
                    title={t("currencyExchange.failureTitle")}
                    buttonText={t("currencyExchange.failureButton")}
                    modalBodyText={t("currencyExchange.failureMessage")}
                    handleClose={() => {
                        setNotificationModalOpen(false);
                    }}
                    handleSubmit={() => {
                        setNotificationModalOpen(false);
                        setModalOpen(true);
                    }}
                    isForm
                    isErrorTitle={true}
                />
            }
            
        </>
    );
};

export default observer(Header);
