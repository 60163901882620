import styled from "styled-components";
import C from "../../../styles/components";

const S = {
    container: styled(C.block)`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin-top: 100px;
        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
        }
    `,
    header: styled(C.text.h2)`
        margin-bottom: 48px;
        margin-top: 48px;
        text-align: center;;
    `,
    content: styled.div`
        max-width: 800px;
    `,
    description: styled.div`
    `,
    imageContainer: styled.div`
        position: relative;
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background: radial-gradient(transparent 40%, ${({ theme }) => theme.colors.background.lowest} 100%);
        }
    `,
    image: styled.img`
        width: 550px;
        max-width: calc(100vw - 48px);
    `,
};

export default S;
