import styled from "styled-components";
import C from "../components";

const M = {
    modalBody: styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        height: 100%;
    `,
    modalOverlay: styled.div`
        position: fixed;
        inset: 0;
        background: ${({ theme }) => theme.colors.background.modalOverlayBackground};
        z-index: 1000;
   `,
    modalBox: styled.div`
        display: block;
        flex-shrink: 0;
        border-radius: 16px;
        background: ${({ theme }) => theme.colors.background.modalBackground};
        box-shadow: 0px 12px 32px 0px ${({ theme }) => theme.colors.background.modalBoxShadow};;
        padding: 24px;
        transform: translate(-50%, -50%);
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 1000;
        box-sizing: border-box;
        width: 400px;
    `,
    headerSection: styled.section`
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
    `,
    header: styled(C.text.h3)<{$isErrorTitle?: boolean}>`
        text-align: center;
        color: ${({ theme, $isErrorTitle }) => $isErrorTitle ? theme.colors.typo.error : theme.colors.typo.white};
    `,
    closeIcon: styled.button`
        background: none;
        border: none;
        position: absolute;
        padding: 0;
        width: 24px;
        height: 24px;
        right: 20px;
        top: 20px;
        cursor: pointer;
    `,
    button: styled.button`
      display: flex;
      height: 44px;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      align-self: stretch;
      background: ${({ theme }) => theme.colors.primary.highest};
      color: ${({ theme }) => theme.colors.typo.black};
      &:disabled {
        background:  ${({ theme }) => theme.colors.background.higher};
        cursor: not-allowed;

        &:hover {
          background:  ${({ theme }) => theme.colors.background.higher};
          color: ${({ theme }) => theme.colors.typo.black};
        }
      }
    `
}

export default M;
