import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import M from "./modalStyle.styled";

import ReactPortal from "./ReactPortal";
import { ReactComponent as CloseCrossIcon } from "../../resources/icons/closeIcon.svg";

export interface ModalProps {
    isOpen: boolean,
    title?: string | ReactNode,
    buttonText?: string,
    modalBodyText?: ReactNode;
    children?: ReactNode;
    buttonDisabled?: boolean;
    handleSubmit?: () => void;
    handleClose?: () => void;
    isForm?: boolean;
    setLoadingState?: (state: boolean) => void;
    isErrorTitle?: boolean;
}

const Modal = ({
    isOpen,
    title,
    buttonText,
    modalBodyText,
    children,
    buttonDisabled = false,
    handleSubmit,
    handleClose,
    isErrorTitle= false,
    setLoadingState,
    isForm = true,
}: ModalProps) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    if (!isOpen) return null;
    const handleCloseModal = () => {
        handleClose?.();
    };

    const handleSubmitModal = () => {
        handleSubmit?.();
    };
    return (
        <ReactPortal wrapperId="react-portal-modal-container">
            <M.modalOverlay />
            <M.modalBox className={`modal ${isOpen ? 'modal-open' : ''}`}>
                <M.closeIcon onClick={handleCloseModal}>
                    <CloseCrossIcon />
                </M.closeIcon>
                <M.modalBody>
                    <M.headerSection>
                        <M.header $isErrorTitle={isErrorTitle}>{title}</M.header>
                    </M.headerSection>
                    {children}
                    <M.button
                        disabled={buttonDisabled}
                        onClick={handleSubmitModal}
                        type={isForm ? "submit" : "button"}
                    >
                        {buttonText}
                    </M.button>
                </M.modalBody>
            </M.modalBox>
        </ReactPortal>
    );
};
export default Modal;
