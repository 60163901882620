import React from "react";
import { useTranslation } from "react-i18next";
import imageSrc from "./image.jpg";
import S from "./MainPagePlatform.styled";
import MainPagePlatformItem from "./item/MainPagePlatformItem";

const MainPagePlatform: React.FC = () => {
    const { t } = useTranslation();

    return (
        <S.container id="block-platform">
            <S.content>
                <S.header1 $primary>
                    {t("mainPage.platform.header1")}
                </S.header1>
                <S.header2>
                    {t("mainPage.platform.header2")}
                </S.header2>
                <S.description>
                    {t("mainPage.platform.description")}
                </S.description>
                <S.items>
                    {([0, 1, 2, 3] as const).map((index) => (
                        <MainPagePlatformItem key={index} index={index} />
                    ))}
                </S.items>
            </S.content>
            <S.image src={imageSrc} alt="background" />
        </S.container>
    );
};

export default MainPagePlatform;
