import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import S from "./Header.styled";

export type TLinkName = "platform" | "tools" | "advantages" | "questions" | "about";

type TProps = {
    name: TLinkName,
    onClick?: () => void,
};

const HeaderLink: React.FC<TProps> = ({
    name,
    onClick: onClickCallback,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [shouldScroll, setShouldScroll] = useState(false);
    
    useEffect(() => {
        if (shouldScroll) {
            const element = document.getElementById(`block-${name}`);
            const main = document.getElementById(`main`);
            if (element && main) {
                const shift = -100;
                main.scrollTo({
                    top: element.offsetTop + shift,
                    behavior: "smooth",
                });
            }
            // Сбросить флаг скроллинга
            setShouldScroll(false);
        }
    }, [shouldScroll, name]);
    
    const onClick = () => {
        if (location.pathname !== "/") {
            navigate(`/?scrollTo=${name}`);
            setShouldScroll(true);
        } else {
            const element = document.getElementById(`block-${name}`);
            const main = document.getElementById(`main`);
            if (element && main) {
                const shift = -100;
                main.scrollTo({
                    top: element.offsetTop + shift,
                });
            }
            onClickCallback?.();
        }
    };
    
    return (
        <>
            <S.link onClick={onClick}>
                {t(`header.links.${name}`)}
            </S.link>
        </>
        
    );
};

export default HeaderLink;
