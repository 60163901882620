import React from "react";
import {IFormik} from "../formikTypes";
import I from "./input.styled";

export interface TProps extends React.InputHTMLAttributes<HTMLInputElement> {
    formik?: IFormik;
    error?: boolean;
    helperText?: React.ReactNode;
    serverErrors?: Record<string, string>;
    resetServerError?: (field: string) => void;
    regex?: RegExp,
    maxIntegerDigits?: number,
    maxDecimalDigits?: number,
    showFiledError?: boolean;
    isCommonError?: string;
    additionalInfo?: string;
    maxPrecision?: number
}

const TextInput: React.FC<TProps> = ({
                                         formik,
                                         showFiledError = false,
                                         serverErrors,
                                         resetServerError,
                                         isCommonError,
                                         regex,
                                         additionalInfo,
                                         maxPrecision,
                                         ...props
                                     }) => {
    let isError;
    let error;
    let {
        value, onChange, onBlur,
    } = props;
    const field = props.name || "";
    if (formik) {
        value = value ? value : formik.values[field];
        onChange = onChange ?? formik.handleChange;
        onBlur = onBlur ?? formik.handleBlur;
        error = serverErrors?.[field] || (formik?.touched[field] && formik.errors[field]);
        isError = !!error || !!isCommonError;
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (maxPrecision) {
            const parsed = event.target.value.split('.')
            if (parsed[1]?.length > maxPrecision) {
                return event.preventDefault();
            }
        }
        resetServerError?.(props.name || "");
        onChange?.(event);
        formik?.handleChange(event);
    };

    return (
        <>
            <I.input
                autoComplete="off"
                {...props}
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                $isError={isError}
                $additionalInfo={!!additionalInfo}
            />
            {additionalInfo &&
                <I.additionalInfo>{additionalInfo}</I.additionalInfo>
            }
            {(isError && showFiledError) && <I.errorMessage>{error || ""}</I.errorMessage>}
        </>
    );
};

export default TextInput;
