import styled from "styled-components";
import C from "../../styles/components";

const S = {
    footer: styled.footer`
        padding: 12px 0;
        background-color: ${({ theme }) => theme.colors.background.lower};    
    `,
    content: styled(C.block)`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        gap: 16px;
        @media (max-width: 900px) {
            flex-direction: column;
        }
    `,
    item: styled.div`
        display: flex;
        gap: 8px;
    `,
    label: styled.div`
        color: ${({ theme }) => theme.colors.typo.medium};
    `,
    link: styled.a`
    `,
    document: styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
      @media (max-width: 900px) {
        align-items: center
      }
    `
};

export default S;
