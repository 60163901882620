import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import S from "./MainPageQuestions.styled";

interface TTexts {
    question: string,
    answer: string,
}

const MainPageQuestions: React.FC = () => {
    const { t } = useTranslation();
    const [openedQuestion, setOpenedQuestion] = useState("");

    const listItems = t("mainPage.questions.items", { returnObjects: true }) as TTexts[];

    const toggleQuestion = (question: string) => {
        setOpenedQuestion((prevQuestion) => question === prevQuestion ? "" : question);
    };

    return (
        <S.container id="block-questions">
            <S.header>
                {t("mainPage.questions.header")}
            </S.header>
            <S.content>
                {listItems.map((item) => (
                    <S.item key={item.question} onClick={() => toggleQuestion(item.question)}>
                        <S.itemHeader>
                            {item.question}
                            <S.itemToggleButton $opened={item.question === openedQuestion} />
                        </S.itemHeader>
                        <S.itemBody $opened={item.question === openedQuestion}>
                            {item.answer}
                        </S.itemBody>
                    </S.item>
                ))}
            </S.content>
        </S.container>
    );
};

export default MainPageQuestions;
