import Modal, { ModalProps } from "../../../../styles/modal/Modal";

const NotificationsModal  = ({
    isOpen,
    title,
    buttonText,
    modalBodyText,
    isErrorTitle,
    handleClose,
    handleSubmit,
}: ModalProps) => {
 
    return (
        <Modal
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            isForm={false}
            isErrorTitle={isErrorTitle}
        >
            <div>{modalBodyText}</div>
        </Modal>
    )
}

export default NotificationsModal;