import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as RuIcon } from "./icons/ru.svg";
import { ReactComponent as EnIcon } from "./icons/en.svg";
import S from "./HeaderLanguage.styled";

const icons = {
    ru: RuIcon,
    en: EnIcon,
};

export interface HeaderLanguageProps {
    isVisible?: boolean
}

const HeaderLanguage: React.FC<HeaderLanguageProps> = ({isVisible}) => {
    const { i18n } = useTranslation();

    const secondLanguage = i18n.language === "ru" ? "en" : "ru";
    const Icon = icons[secondLanguage];
    
    const toggleLanguage = () => {
        i18n.changeLanguage(secondLanguage);
    };

    return (
        <S.button onClick={toggleLanguage} $isVisible={isVisible}>
            <Icon />
            {secondLanguage}
        </S.button>
    );
};

export default HeaderLanguage;
