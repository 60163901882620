import styled from "styled-components";
import C from "../../../../styles/components";

const S = {
    container: styled(C.block)`
        height: calc(100vh - ${({ theme }) => theme.headerHeight});
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        @media (max-width: 1000px) {
            height: auto;
            flex-direction: column;
            padding-top: 60px;
            margin-bottom: -60px;
        }
    `,
    content: styled.div`
        backdrop-filter: blur(10px);
        border-radius: 16px;
        width: fit-content;
        @media (max-width: 1000px) {
            width: 100%;
        }
    `,
    header1: styled(C.text.h1)`
        margin-bottom: 16px;
    `,
    header2: styled(C.text.h1)`
        margin-bottom: 32px;
    `,
    list: styled(C.list)`
        max-width: 500px;
        @media (max-width: 1000px) {
            max-width: none;
        }
    `,
    image: styled.img`
        position: relative;
        z-index: -1;
        max-height: calc(100vh - ${({ theme }) => theme.headerHeight});
        max-width: 100vw;
        /* margin-left: -40vw;
        margin-right: -150px; */
    `
};

export default S;
