import styled from "styled-components";
import C from "../../../../styles/components";

const S = {
    container: styled(C.block)``,
    header: styled(C.text.h2)`
        margin-bottom: 32px;
    `,
    formContainer: styled.form`
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-self: stretch;
    `,
    inputAndTitleContainer: styled.div`
      display: flex;
      flex-shrink: inherit;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 100%;
      color: ${({ theme }) => theme.colors.typo.white};
    `,
    row: styled.div`
      display: grid;
      grid-template-columns: 67% 30%;
      box-sizing: border-box;
      align-items: flex-end;
      gap: 8px;
      align-self: stretch;
    `,
    field: styled.span`
        display: flex;
        flex-direction: column;
        gap: 8px;
    `,
    boldText: styled.div`
      color: ${({ theme }) => theme.colors.typo.white};
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    `,
    checkboxRow: styled.div`
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-self: stretch;
    `,
    checkbox: styled.input`
      display: flex;
      width: 20px;
      height: 20px;
      padding: 2px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: ${({ theme }) => theme.colors.primary.highest};
    `,
    text: styled.span`
      color: ${({ theme }) => theme.colors.typo.white};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      width: fit-content;
      flex-direction: row;
      flex-wrap: wrap
    `,
    link: styled.a`
      color: ${({ theme }) => theme.colors.primary.highest};
      text-decoration: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &:focus {
        color: ${({ theme }) => theme.colors.primary.lowest};
      }

      &:hover {
        color: ${({ theme }) => theme.colors.primary.lowest};
      }
    `,
};

export default S;
