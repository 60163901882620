import styled from "styled-components";
import C from "../../styles/components";
import {ReactComponent as LogoIcon} from "../../resources/icons/logo.svg";

const S = {
    logo: styled(LogoIcon)`
        margin-right: auto;
        cursor: pointer;
    `,
    header: styled.header`
        height: ${({theme}) => theme.headerHeight};
        position: fixed;
        inset: 0;
        background-color: ${({theme}) => theme.colors.background.low}70;
        backdrop-filter: blur(10px);
        z-index: 9;
    `,
    content: styled(C.block)`
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
    `,
    menuButton: styled.button`
        display: none;
        color: ${({theme}) => theme.colors.primary.lowest};

        @media (max-width: 900px) {
            display: initial;
        }
    `,
    linksList: styled.div`
        display: flex;
        gap: 48px;
        margin: 0 auto;

        @media (max-width: 900px) {
            display: none;
        }
    `,
    link: styled.a`
    `,
    button: styled.button`
        display: flex;
        height: 44px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        align-self: center;
        background: ${({theme}) => theme.colors.primary.highest};
        color: ${({theme}) => theme.colors.typo.black};

        &:disabled {
            background: ${({theme}) => theme.colors.background.higher};
            cursor: not-allowed;

            &:hover {
                background: ${({theme}) => theme.colors.background.higher};
                color: ${({theme}) => theme.colors.typo.black};
            }
        }
    `,
};

export default S;
