import { makeAutoObservable } from "mobx";
import {ExchangeTypes} from "../types/exchange";

const defaultData: ExchangeTypes = {
    sendValue: "",
    sendCurrency: "USD",
    receiveValue: "",
    receiveCurrency: "BTC",
    fullName: "",
    email: "",
}

class ExchangeStore {
    private _data: ExchangeTypes

    constructor() {
        makeAutoObservable(this);
        this._data = defaultData
    }

    public setData(newData: typeof this._data) {
        this._data = newData;
    }

    public clearData() {
        this._data = defaultData;
    }

    public get data () {
        return this._data;
    }


}

const exchangeStore = new ExchangeStore();

export default exchangeStore;