import React from 'react';
import styled from 'styled-components';
import Spinner from "./spinner.styled";

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Overlay: React.FC = () => {
    return (
        <OverlayContainer>
            <Spinner />
        </OverlayContainer>
    );
};

export default Overlay;