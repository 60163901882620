import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import "./i18n";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <BrowserRouter basename="/">
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </BrowserRouter>
);
