import styled from "styled-components";
import C from "../../../../styles/components";

const S = {
    container: styled(C.block)`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 48px;
    `,
    header: styled(C.text.h2)`
    `,
};

export default S;
