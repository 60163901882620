import parse from "html-react-parser";
import React from "react";
import { useTranslation } from "react-i18next";
import S from "./documents.styled";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    
    return (
        <S.container>
            <S.content>
                {parse(t("privacyPolice.header"))}
                <section>
                    {parse(t("privacyPolice.introduction"))}
                </section>
                <section>
                    {parse(t("privacyPolice.personalDataTitle"))}
                    {parse(t("privacyPolice.personalData"))}
                </section>
                <section>
                    {parse(t("privacyPolice.rights"))}
                </section>
                <section>
                    {parse(t("privacyPolice.postscript"))}
                </section>
            </S.content>
        </S.container>
    )
};

export default PrivacyPolicy;
