import React from "react";
import { useTranslation } from "react-i18next";
import S from "./MainPageTools.styled";
import MainPageToolsItem from "./item/MainPageToolsItem";

const MainPageTools: React.FC = () => {
    const { t } = useTranslation();

    return (
        <S.container id="block-tools">
            <S.header>
                {t("mainPage.tools.header")}
            </S.header>
            {([0, 1] as const).map((index) => (
                <MainPageToolsItem key={index} index={index} />
            ))}
        </S.container>
    );
};

export default MainPageTools;
