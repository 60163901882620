import styled from "styled-components";
import ListItemIcon from "../../../resources/icons/list-item.styled";

const S = {
    li: styled.li`
        display: flex;
        align-items: center;
        gap: 8px;
        list-style: none;
    `,
    icon: styled(ListItemIcon)`
        flex-shrink: 0;
    `,
};

export default S;
