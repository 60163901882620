import React from "react";
import { useTranslation } from "react-i18next";
import C from "../../../../styles/components";
import ListItem from "../../../ui/listItem/ListItem";
import imageSrc from "./image.jpg";
import S from "./MainPageJoin.styled";

const MainPageJoin: React.FC = () => {
    const { t } = useTranslation();

    const listItems = t("mainPage.join.items", { returnObjects: true }) as string[];

    return (
        <S.container>
            <S.imageContainer>
                <S.image alt="join" src={imageSrc} />
            </S.imageContainer>
            <S.content>
                <S.header>
                    {t("mainPage.join.header")}
                </S.header>
                <S.description>
                    {t("mainPage.join.description")}
                </S.description>
                <C.list>
                    {listItems.map((text) => (
                        <ListItem key={text}>
                            {text}
                        </ListItem>
                    ))}
                </C.list>
            </S.content>
        </S.container>
    );
};

export default MainPageJoin;
