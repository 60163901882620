import styled from "styled-components";

const S = {
    button: styled.button<{$isVisible?: boolean}>`
        text-transform: uppercase;
        height: 24px;
        width: 64px;

        display: flex;
        align-items: center;
        gap: 4px;
        margin-right: 20px;
        
        @media (max-width: 900px) {
            display: ${props => (props.$isVisible ? 'flex' : 'none')};
            margin-right: 0;
        }

        svg {
            border-radius: 50%;
            height: 24px;
            width: 24px;
        }
    `,
};

export default S;
