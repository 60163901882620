import React from "react";
import { useTranslation } from "react-i18next";
import imageSrc from "./image.jpg";
import S from "./MainPageAbout.styled";

const MainPageAbout: React.FC = () => {
    const { t } = useTranslation();

    return (
        <S.container id="block-about">
            <S.content>                
                <S.header>
                    {t("mainPage.about.header")}
                </S.header>
                <S.description>
                    {t("mainPage.about.description")}
                </S.description>
            </S.content>
            <S.imageContainer>
                <S.image src={imageSrc}/>
            </S.imageContainer>
        </S.container>
    );
};

export default MainPageAbout;
