import { RestRequest } from "./requestServices";
import envVariables from "../constants/envVariables";
import {CryptoCurrencyCode, CurrencyCode} from "../types/exchange";

export interface FeedbackData {
    replyTo?: string;
    subject: string;
    message: string;
}

export interface GetRateRequest {
    Сrypto?: CryptoCurrencyCode,
    Fiat?: CurrencyCode,
}

const sendFeedback = (data: FeedbackData) =>
    RestRequest.post(envVariables.nodeApiUrl + '/sendFeedback', {}, data);

const getRate = (params: GetRateRequest) =>
    RestRequest.get(envVariables.djangoApiUrl + '/rate', params);

export default {sendFeedback, getRate}
