import styled from "styled-components";
import C from "../../../../styles/components";

const S = {
    container: styled(C.block)`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 48px;

        @media (max-width: 1200px) {
            flex-direction: column;
            gap: 16px;
        }
    `,
    content: styled.div`
        backdrop-filter: blur(10px);
        border-radius: 16px;
        width: fit-content;
    `,
    header1: styled(C.text.h2)`
        margin-bottom: 16px;
    `,
    header2: styled(C.text.h2)`
        margin-bottom: 32px;
    `,
    description: styled.div`
        margin-bottom: 24px;
    `,
    items: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    image: styled.img`
        max-height: 400px;
        max-width: calc(100vw - 48px);
    `
};

export default S;
