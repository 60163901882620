import styled, { css } from "styled-components";
import C from "../../../../styles/components";

const S = {
    container: styled(C.block)``,
    header: styled(C.text.h2)`
        margin-bottom: 32px;
    `,
    content: styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
    `,
    item: styled.div`
        padding: 48px 24px;
        border-radius: 24px;
        background-color: ${({ theme }) => theme.colors.background.lower};
        cursor: pointer;

        &:hover {
            background-color: ${({ theme }) => theme.colors.background.low};
        }
        &:active {
            background-color: ${({ theme }) => theme.colors.background.medium};
        }
    `,
    itemHeader: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${({ theme }) => theme.colors.typo.highest};
        font-size: 18px;
        padding-right: 24px;
        @media (max-width: 600px) {
            font-size: 16px;
        }
    `,
    itemToggleButton: styled.div<{ $opened: boolean }>`
        position: relative;

        &:before,
        &:after {
            transition: all .5s;
            position: absolute;
            top: -12px;
            left: 12px;
            content: '';
            height: 24px;
            width: 0px;
            border-left: 2px solid ${({ theme }) => theme.colors.typo.highest};
        }
        &:before {
            ${({ $opened }) => ($opened ? css`
                transform: rotate(-135deg);
            ` : css`
                transform: rotate(0);
            `)}    
        }
        &:after {
            ${({ $opened }) => ($opened ? css`
                transform: rotate(-45deg);
            ` : css`
                transform: rotate(90deg);
            `)}    
        }
    `,
    itemBody: styled.div<{ $opened: boolean }>`
        transition: all .5s;
        overflow: hidden;

        ${({ $opened }) => ($opened ? css`
            max-height: 100px;
            padding-top: 24px;
            @media (max-width: 800px) {
                max-height: 300px;
            }
        ` : css`
            max-height: 0;
            padding-top: 0;
        `)}    
    `,
};

export default S;
