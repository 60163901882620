import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    
    * {
        box-sizing: border-box;
    }
    
    html {
        height: 100%;


        body {
            overflow-y: hidden;
            height: 100%;
            margin: 0;
            padding: 0;
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;

            min-width: 360px;

            background-color: ${({ theme }) => theme.colors.background.lowest};
            color: ${({ theme }) => theme.colors.typo.high};

            text-underline-offset: 4px;
            
            #root {
                height: 100%;
                overflow-y: hidden;
            }
        }
    }
    
    
    ul {
        margin: 0;
        padding: 0;
    }
    button {
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        color: currentColor;
    }

    a {
        text-decoration: none;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.typo.highest};

        &:hover {
            color: ${({ theme }) => theme.colors.typo.high};
        }
        &:active {
            color: ${({ theme }) => theme.colors.typo.low};
        }
    }
`;

export default GlobalStyles;
