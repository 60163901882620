import styled from "styled-components";

const S = {
    link: styled.a`
        cursor: pointer;
        color: ${({ theme }) => theme.colors.typo.highest};

        &:hover {
            color: ${({ theme }) => theme.colors.typo.high};
        }
        &:active {
            color: ${({ theme }) => theme.colors.typo.low};
        }
    `,
};

export default S;
