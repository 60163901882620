import { ReactNode, useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

const defaultReactPortalProps = {
    wrapperId: "react-portal",
};

type ReactPortalProps = {
    children: ReactNode;
    wrapperId: string;
};

const ReactPortal = ({
    children,
    wrapperId = defaultReactPortalProps.wrapperId,
}: ReactPortalProps) => {
    const [wrapper, setWrapper] = useState<Element | null>(null);
    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        let created = false;
        if (!element) {
            created = true;
            const documentWrapper = document.createElement("div");
            documentWrapper.setAttribute("id", wrapperId);
            document.body.appendChild(documentWrapper);
            element = documentWrapper;
        }
        setWrapper(element);
        return () => {
            if (created && element?.parentNode) {
                element.parentNode.removeChild(element);
            }
        };
    }, [wrapperId]);
    if (wrapper === null) return null;
    return createPortal(children, wrapper);
};

export default ReactPortal;
