import styled from "styled-components";
import C from "../../../../styles/components";

const S = {
    container: styled(C.block)``,
    header: styled(C.text.h2)`
        margin-bottom: 48px;
        text-align: center;
    `,
    content: styled.div`
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 24px;
        
        @media (max-width: 1200px) {
            flex-direction: column-reverse;
            gap: 8px;
        }
    `,
    item: styled.div`
        flex-basis: 33%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        color: ${({ theme }) => theme.colors.typo.white};
        background-color: ${({ theme }) => theme.colors.primary.high}70;
        padding: 24px;
        border-radius: 24px;
    `,
    itemHeader: styled.div`
        font-size: 18px;
        font-weight: 600;

        display: flex;
        align-items: center;
        gap: 24px;
    `,
    iconContainer: styled.div`
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        background-color: ${({ theme }) => theme.colors.primary.medium};
        flex-shrink: 0;
        svg {
            width: 32px;
            height: 32px;
        }
    `,
    itemDescription: styled.div`
    `,
};

export default S;
