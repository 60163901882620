import styled from "styled-components";
import C from "../../../../styles/components";

const S = {
    container: styled(C.block)`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 48px;
        @media (max-width: 900px) {
            flex-direction: column-reverse;
            gap: 8px;
        }
    `,
    image: styled.img`
        max-height: 400px;
        max-width: calc(100vw - 48px);
    `,
    content: styled.div`
        max-width: 500px;
    `,
    header: styled(C.text.h2)`
        margin-bottom: 24px;
    `,
    description: styled.div`
        margin-bottom: 32px;
    `,
    imageContainer: styled.div`
        position: relative;
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background: radial-gradient(transparent 50%, ${({ theme }) => theme.colors.background.lowest} 100%);
        }
    `,
};

export default S;
