import React from "react";
import HeaderLink, { TLinkName } from "../link/HeaderLink";
import S from "./HeaderMenu.styled";
import HeaderLanguage from "../language/HeaderLanguage";

const linkNames: TLinkName[] = ["platform", "tools", "advantages", "questions"];

type TProps = {
    open: boolean;
    onClick: () => void,
}

const HeaderMenu: React.FC<TProps> = ({
    open,
    onClick,
}) => {
    return (
        <S.container $isOpen={open}>
            {linkNames.map((name) => (
                <HeaderLink
                    key={name}
                    name={name}
                    onClick={onClick}
                />
            ))}
            <HeaderLanguage isVisible />
        </S.container>
    );
};

export default HeaderMenu;
