import React from "react";
import { useTranslation } from "react-i18next";
import ListItem from "../../../ui/listItem/ListItem";
import imageSrc from "./image.jpg";
import S from "./MainPageBanner.styled";
import C from "../../../../styles/components";

const MainPageBanner: React.FC = () => {
    const { t } = useTranslation();

    const listItems = t("mainPage.banner.items", { returnObjects: true }) as string[];

    return (
        <S.container>
            <S.content>
                <S.header1 $primary>
                    {t("mainPage.banner.header1")}
                </S.header1>
                <S.header2>
                    {t("mainPage.banner.header2")}
                </S.header2>
                <S.list>
                    {listItems.map((itemText) => (
                        <ListItem key={itemText}>
                            {itemText}
                        </ListItem>
                    ))}
                </S.list>
            </S.content>
            <C.imageGradient>
                <S.image src={imageSrc} alt="background" />
            </C.imageGradient>
        </S.container>
    );
};

export default MainPageBanner;
