import styled, { css } from "styled-components";

type THeaderOptions = { $primary?: boolean };

const header = (options: THeaderOptions) => css`
    line-height: 100%;
    margin: 0;
    color: ${({ theme }) => (options.$primary ? theme.colors.primary.low : theme.colors.typo.highest)};
`;

const C = {
    block: styled.div`
        position: relative;
        max-width: ${({ theme }) => theme.blockWidth};
        margin: 0 auto 120px;
        padding: 0 8px;
    `,
    list: styled.ul`
        display: flex;
        flex-direction: column;
        gap: 8px;
    `,
    imageGradient: styled.div`
        position: relative;
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(0deg, transparent 90%, ${({ theme }) => theme.colors.background.lowest} 100%),
                        linear-gradient(90deg, transparent 90%, ${({ theme }) => theme.colors.background.lowest} 100%),
                        linear-gradient(180deg, transparent 90%, ${({ theme }) => theme.colors.background.lowest} 100%),
                        linear-gradient(270deg, transparent 90%, ${({ theme }) => theme.colors.background.lowest} 100%);
        }
    `,
    text: {
        h1: styled.h1<{ $primary?: boolean }>`
            font-size: 40px;
            ${({ $primary }) => header({ $primary })};
        `,
        h2: styled.h2<{ $primary?: boolean }>`
            font-size: 32px;
            ${({ $primary }) => header({ $primary })};
        `,
        h3: styled.h3<{ $primary?: boolean }>`
            font-size: 24px;
            ${({ $primary }) => header({ $primary })};
        `,
    },
};

export default C;
