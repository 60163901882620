import React from "react";
import S from "./ListItem.styled";

export interface TProps extends React.HTMLAttributes<HTMLLIElement> {

}

const ListItem: React.FC<TProps> = ({
    children,
    ...props
}) => {
    return (
        <S.li {...props}>
            <>
                <S.icon />
                {children}
            </>
        </S.li>
    );
};

export default ListItem;
