import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../resources/icons/logo.svg";
import S from "./Footer.styled";
import HeaderLink from "../header/link/HeaderLink";

const Footer: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <S.footer>
            <S.content>
                <LogoIcon />
                <HeaderLink name="about" />
                <S.item>
                    <S.label>
                        {t("footer.contact")}
                    </S.label>
                    <S.link href="tel:+996707355000">
                        +996 707 355 000
                    </S.link>
                </S.item>
                <S.item>
                    <S.label>
                        {t("footer.support")}
                    </S.label>
                    <S.link href="mailto:support@cashsat.com">
                        support@cashsat.com
                    </S.link>
                </S.item>
                <S.document>
                    <S.label>
                        {t("footer.documents")}
                    </S.label>
                    <S.item>
                        <S.link href="terms-of-use">
                            {t("footer.publicOffer")}
                        </S.link>
                    </S.item>
                    <S.item>
                        <S.link href="privacy-policy">
                            {t("footer.privacyPolicy")}
                        </S.link>
                    </S.item>
                </S.document>
            </S.content>
        </S.footer>
    );
};

export default Footer;
