import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const IconWrapper = styled.div<{ checked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: ${({ theme , checked }) => checked ?  theme.colors.primary.highest : "transparent"};
  border-radius: 8px;
  border: 2px solid ${({ theme}) => theme.colors.primary.highest};
  svg {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
