import React from "react";
import { CheckboxContainer, HiddenCheckbox, IconWrapper } from "./checkbox.styled";
import { ReactComponent as CheckIcon } from "../../resources/icons/check.svg";

interface CheckboxProps {
    checked: boolean;
    onChange: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange }) => (
    <CheckboxContainer onClick={onChange}>
        <HiddenCheckbox checked={checked} onChange={onChange} />
        <IconWrapper checked={checked}>
            <CheckIcon />
        </IconWrapper>
    </CheckboxContainer>
);

export default Checkbox;