import { makeAutoObservable } from "mobx";

class RequestStore {
    private isLoading: boolean;
    private isRequestSuccess: boolean;
    
    constructor() {
        makeAutoObservable(this);
        this.isLoading = false;
        this.isRequestSuccess = false;
    }
    
    public set setLoadingState(state: boolean) {
        this.isLoading = state;
        if (state) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }
    
    public get getLoadingState () {
        return this.isLoading;
    }
    
    public set setRequestState(state: boolean) {
        this.isRequestSuccess = state;
    }
    
    public get getIsRequestSuccess () {
        return this.isRequestSuccess;
    }
}

const requestStore = new RequestStore();

export default requestStore;