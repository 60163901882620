import styled from "styled-components";
import { ReactComponent as Icon } from "./list-item.svg";

const ListItemIcon = styled(Icon)`
    path {
        fill: ${({ theme }) => theme.colors.primary.low};
    }
`;


export default ListItemIcon;
