import Axios, { AxiosResponse } from 'axios';

type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE';

const method: { [K in HttpMethod]: K } = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE'
};

interface RequestParams {
    [key: string]: any;
}

const sendRequest = (
    method: HttpMethod,
    url: string,
    params?: RequestParams,
    data?: any
): Promise<AxiosResponse<any>> => {
    return Axios.request({
        method,
        url,
        params,
        data,
    });
};

type Endpoint = string;

export const RestRequest = {
    get: (endpoint: Endpoint, params?: RequestParams, data?: any) =>
        sendRequest(method.GET, endpoint, params, data),
    put: (endpoint: Endpoint, params?: RequestParams, data?: any) =>
        sendRequest(method.PUT, endpoint, params, data),
    post: (endpoint: Endpoint, params?: RequestParams, data?: any) =>
        sendRequest(method.POST, endpoint, params, data),
    delete: (endpoint: Endpoint, params?: RequestParams, data?: any) =>
        sendRequest(method.DELETE, endpoint, params, data),
};
