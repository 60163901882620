import { SelectProps } from "antd";
import React, {useRef, useState} from "react";
import I from "./input.styled"
import { TProps } from "./TextInput";

export type OptionType = { label: string; value: string };

type DropdownProps = {
    value?: string | number | boolean
    saveFormat?: "value" | "label"
    onChange?: (value: unknown) => void
} & SelectProps<unknown, OptionType> & Omit<TProps, "onChange">

const CurrencySelect = ({
    formik,
    serverErrors,
    resetServerError,
    placeholder,
    onChange,
    name = "",
    options,
    saveFormat,
    allowClear = true,
    ...props
}: DropdownProps) => {
    const [focus, setFocus] = useState(false);
    
    let isError = false;
    let error;
    let {
        value, onBlur,
    } = props;
    
    if (formik) {
        value = formik.values[name];
        onBlur = onBlur ?? formik.handleBlur;
        error = serverErrors?.[name] || (formik?.touched[name] && formik.errors[name]);
        isError = !!error;
    }
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const ref = useRef<any>(null)

    const handleChange = (newValue: unknown, option: OptionType) => {
        let newFormikValue = newValue;
        if (newValue === undefined) {
            newFormikValue = null;
        } else if (saveFormat === "label" && option?.label) {
            newFormikValue = option.label;
        }
        formik?.setFieldValue(name, newFormikValue);
        formik?.setFieldTouched(name, false);
        resetServerError?.(name);
        onChange?.(newFormikValue);
        ref?.current.blur();
    };
    
    return (
        <I.dropdown
            ref={ref}
            value={value}
            // @ts-ignore
            onChange={handleChange}
            filterOption={filterOption}
            onBlur={onBlur}
            name={name}
            disabled={props.disabled}
            options={options}
            showSearch
            allowClear={allowClear}
            placeholder={placeholder}
        />
    );
    
};

export default CurrencySelect;
