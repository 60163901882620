import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExchangeLogo } from "./icons/exchange.svg";
import { ReactComponent as SendLogo } from "./icons/send.svg";
import { ReactComponent as ReceiveLogo } from "./icons/receive.svg";
import { ReactComponent as WalletLogo } from "./icons/wallet.svg";
import S from "./MainPagePlatformItem.styled";

interface TProps {
    index: 0 | 1 | 2 | 3;
}

const icons = [ExchangeLogo, SendLogo, ReceiveLogo, WalletLogo];

const MainPagePlatformItem: React.FC<TProps> = ({
    index,
}) => {
    const { t } = useTranslation();

    const listItems = t("mainPage.platform.items", { returnObjects: true }) as string[];
    const text = listItems[index];

    const Icon = icons[index];

    return (
        <S.container>
            <S.iconContainer>
                <Icon />
            </S.iconContainer>
            {text}
        </S.container>
    );
};

export default MainPagePlatformItem;
