
const theme = {
    headerHeight: "60px",
    blockWidth: "1200px",
    colors: {
        primary: {
            highest: "#dfb052",
            higher: "#daa131",
            high: "#d5921a",
            medium: "#d18715",
            low: "#cc7a0f",
            lower: "#c56c0a",
            lowest: "#ba5706",
        },
        background: {
            lowest: "#111111",
            lower: "#222222",
            low: "#333333",
            medium: "#444444",
            high: "#555555",
            higher: "#666666",
            highest: "#777777",
            modalOverlayBackground: "rgba(36, 36, 36, 0.40)",
            modalBackground: "#313131",
            modalBoxShadow: "rgba(0, 0, 0, 0.50)",
            inputBackground: "#1C1C1C"
        },
        typo: {
            highest: "#ECEFF1FF",
            higher: "#ECEFF1D8",
            high: "#ECEFF1B4",
            medium: "#ECEFF190",
            low: "#ECEFF16C",
            lower: "#ECEFF148",
            lowest: "#ECEFF124",
            error: "#DF5252",
            success: "#34C759",
            white: "#ffffff",
            black: "#000000",
            additionalInfo: "rgba(255, 255, 255, 0.70)"
        },
        border: {
            inputBorder: "rgba(255, 255, 255, 0.30)",
        }
    },
};

export type TTheme = typeof theme;

export default theme;
