import React from "react";
import { useTranslation } from "react-i18next";
import image1Src from "./images/image-1.jpg";
import image2Src from "./images/image-2.jpg";
import C from "../../../../../styles/components";
import ListItem from "../../../../ui/listItem/ListItem";
import S from "./MainPageToolsItem.styled";

interface TProps {
    index: 0 | 1;
}

interface TTexts {
    label: string,
    header: string,
    description: string,
    items: string[],
}

const images = [image1Src, image2Src];

const MainPageToolsItem: React.FC<TProps> = ({
    index,
}) => {
    const { t } = useTranslation();

    const listItems = t("mainPage.tools.items", { returnObjects: true }) as TTexts[];
    const texts = listItems[index];

    const imageSrc = images[index];

    return (
        <S.container $reverse={index === 0}>
            <S.image src={imageSrc}/>
            <S.content>
                <S.label>
                    {texts.label}
                </S.label>
                <C.text.h3>
                    {texts.header}
                </C.text.h3>
                <div>
                    {texts.description}
                </div>
                <C.list>
                    {texts.items.map((itemText) => (
                        <ListItem key={itemText}>
                            {itemText}
                        </ListItem>
                    ))}
                </C.list>
            </S.content>
        </S.container>
    );
};

export default MainPageToolsItem;
