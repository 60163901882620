import {Select} from "antd";
import styled from "styled-components";

const I = {
    inputWrapper: styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `,
    input: styled.input<{ $isError?: boolean, $additionalInfo?: boolean }>`
        display: flex;
        padding: 10px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 6px;
        border: 1px solid ${({theme, $isError}) => ($isError
                        ? theme.colors.typo.error :
                        theme.colors.border.inputBorder
        )};
        outline: ${({theme, $isError}) => ($isError
                        ? 'none' :
                        ''
        )};
        color: ${({theme, $isError}) => $isError ? theme.colors.typo.error : theme.colors.typo.white};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        background: ${({theme}) => theme.colors.background.inputBackground};
        margin-bottom: ${(props) => props.$additionalInfo ? "4px" : "0px"};

        &::placeholder {
            font-weight: 500;
            font-size: 16px;
            font-style: normal;
            color:${({theme}) => theme.colors.typo.low};
        }
    `,
    additionalInfo: styled.div`
        color: ${({theme}) => (theme.colors.typo.additionalInfo)};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
    `,
    additionalInfoDate: styled.div`
        color: ${({theme}) => (theme.colors.typo.additionalInfo)};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        margin-top: 4px;
    `,
    errorMessage: styled.span`
        color: ${({theme}) => theme.colors.typo.error};
        justify-content: flex-start;
        font-size: 14px;
    `,
    dropdown: styled.input.attrs<{ $isError?: boolean }>({
        as: Select,
    })`
        background: ${({theme}) => theme.colors.background.inputBackground};
        color: ${({theme, $isError}) => $isError ? theme.colors.typo.error : theme.colors.typo.white};
        padding: 10px 12px;
        font-size: 16px;
        height: 44px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid ${({theme, $isError}) => ($isError
                        ? theme.colors.typo.error :
                        theme.colors.border.inputBorder
        )};

        &&.ant-select .ant-select-selector {
            border: none !important;
            background: inherit !important;;
            box-shadow: none !important;;
            border-radius: inherit;
            padding: 0;
            color: ${({theme}) => (theme.colors.typo.white)} !important;


            .ant-select-selection-search {
                    inset-inline-start: 0;
                    inset-inline-end: 0;
            }
            
            input {
                cursor: default !important;
                color: ${({theme}) => (theme.colors.typo.white)} !important;
                text-shadow: 0 0 0 black;

                &:hover {
                    color: ${({theme}) => (theme.colors.typo.white)} !important;
                }
            }

            .ant-select-selection-placeholder {
                color: #f0f0f0;
            }

        }

        &&.ant-select .ant-select-arrow {
            color: ${({theme}) => (theme.colors.typo.white)} !important;

            &:hover {
                color: ${({theme}) => (theme.colors.typo.white)} !important;
            }
        }

    `
}

export default I;