import { observer } from "mobx-react-lite";
import React, {useEffect} from "react";
import requestStore from "../../../store/requestStore";
import Overlay from "../../../styles/spinner/Overlay";
import MainPageBanner from "./banner/MainPageBanner";
import MainPagePlatform from "./platform/MainPagePlatform";
import MainPageTools from "./tools/MainPageTools";
import MainPageAdvantages from "./advantages/MainPageAdvantages";
import MainPageJoin from "./join/MainPageJoin";
import MainPageQuestions from "./questions/MainPageQuestions";
import MainPageAbout from "./About/MainPageAbout";
import {useLocation} from "react-router-dom";

const MainPage: React.FC = () => {

    const { pathname } = useLocation();
    useEffect(() => {
        const main = document.getElementById(`main`);
        main?.scrollTo({
            top: 0,
        });
    }, [pathname]);

    return (
        <>
            <MainPageBanner />
            <MainPagePlatform />
            <MainPageTools />
            <MainPageAdvantages />
            <MainPageJoin />
            <MainPageAbout />
            <MainPageQuestions />
            {requestStore.getLoadingState && <Overlay />}
        </>
    );
};

export default observer(MainPage);
